.terminal-prerecord__services {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 20px;
    padding: 25px;
  }
  
  .terminal-prerecord__service {
    padding: 15px 25px;
    width: calc((100% - 40px) / 3);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(0,0,0,.1);
  }