@import 'colors';
@import 'multiSelect';
@import 'npsCard';
//
@import 'board2/stylizationOfDevices';
@import './src/old/styles/board/boardEditor/index.scss';
//
@import 'Tooltip';
@import 'MultiSelectNew';
@import 'Webservice';

@import './src/old/views/Control/QualityTablet/components/ColorPicker/ColorPicker.scss';


*{
  padding: 0;
  margin: 0;
  border: 0;
}

.editorSpace {
  min-width: 100%;
}
.tox-notifications-container {
  display: none !important;
}
.flex {
  display: flex;
}
.preEntryBox canvas {
  max-width: 90vw;
  max-height: 120vh;
}
.infoPreEntryText {
  display: flex;
  > * {
    margin-right: 35px;
  }
  span {
    padding: 5px 15px;
  }
}
.closeColor {
  position: absolute;
  padding: 10px 25px;
  transform: translateX(-50%);
  left: 50%;
  bottom: 12px;
  background-color: #fc4c02;
  cursor: pointer;
  color: white;
  z-index: 3;
}
.officeParamsBox {
  p {
    margin-bottom: 5px;
  }
}
.datePickerRange {
  align-items: center;
  input {
    margin-right: 30px;
  }
  span {
    margin-right: 10px;
  }
}
.createOneBox {
  display: flex;
  > div {
    width: 50%
  }
}

.template-settings {
  p {
    margin-bottom: 15px;
  }
  .box {
    display: flex;
    margin: 0 -5px;
    margin-bottom: 30px;
    > div {
      cursor: pointer;
      min-width: 150px;
      border: 1px solid rgba(0,0,0,.1);
      margin: 0 5px;
      padding: 10px 15px;
      display: flex;
      transition: 0.1s ease-out;
      align-items: center;
      justify-content: center;
    }
  }
}
.queueBox {
  position: relative;
  display: flex;
  min-height: 600px;
  max-width: 600px;
  min-width: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px;
  h3 {
    position: relative;
    width: 100%;
    text-transform: uppercase;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  h3 b {
    color: #fc4c02;
    margin-left: 15px;
  }
  h3 span {
    position: absolute;
    top: 5px;
    left: 0px;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 3px;
    background: #fc4c02;
    color: white;
    cursor: pointer;
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
  }

  .box .item:hover, .nextButton:hover {
    background: #fc4c02;
    color: white;
  }
  .nextButton {
    margin-top: 30px !important;
  }
  .box .item, .nextButton {
    margin: 5px;
    transition: 0.1s ease-out;
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.2);
    cursor: pointer;
  }
  .monthBox {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    list-style: none;
  }
  .monthBox svg, .monthBox span {
    width: 25px;
    height: 25px;
    cursor: pointer;
    fill: #313131;
  }
  .monthBox svg:hover {
    fill: #fc4c02;
  }
  .filterBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    width: 100%;
  }
  .filterBox p {
    margin: 0 30px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
  }
  .filterBox .shadow {
    min-width: 25px;
    min-height: 25px;
    cursor: default;
  }
  svg.right {
    transform: rotate(180deg);
  }
  .monthBox li {
    width: calc(100% / 7 - 10px);
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,.1);
    color: #4b4b4b;
    height: 50px;
  }
  .monthBox li.open {
    color: #0f0f0f;
    border: 2px solid #fc4c02;
    cursor: pointer;
  }
  .monthBox li.active {
    background: #fc4c02;
    color: white;
  }

  .monthBox .title {
    background: #fc4c02;
    color: white;
  }
  .monthBox .title::after {
    display: none;
  }
  .timeBox {
    margin-bottom: 50px;
    max-height: 500px;
    overflow: auto;
  }

  .timeBox ul {
    display: flex;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    list-style: none;
  }
  .timeBox .item {
    cursor: pointer;
    padding: 15px 25px;
    margin: 5px;
    border: 1px solid rgba(0,0,0,.1);
    &:hover {
      background: #fc4c02;
      color: white;
    }
  }
  .timeBox .item.active {
    background: #fc4c02;
    color: white;
  }
  .preloader {
    width: 100px;
    height: 100px;
    margin-top: 50px;
    position: relative;
  }
  .preloader img {
    position: absolute;
    width: 67%;
    height: 67%;
    top: 11px;
    left: 12px;
  }

  @keyframes PreloaderAnimation {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .preloader .lines {
    display: block;
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: rgba(245, 128, 32, 0.2);
    animation-name: PreloaderAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .preloader .lines::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    margin: -5px;
    right: 0px;
    bottom: 0px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: rgb(245,192,159);
    animation-name: PreloaderAnimation;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .preloader .lines::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: rgb(245, 160, 97);
    animation-name: PreloaderAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .print p span {
    font-weight: bold;
    color: #fc4c02;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    .inputGroup {
      p {
        margin-bottom: 10px;
      }
      margin-bottom: 25px;
    }
  }

  .form input {
    width: 100%;
    height: 50px;
    font-size: 22px;
    padding: 0 15px;
  }
  input:focus {
    border: 2px solid #fc4c02;
    outline: none;
  }
  input:valid {
    border: 2px solid #00e200 !important;
  }
  .inputGroup span {
    color: #fc4c02;
    font-weight: bold;
    margin-left: 5px;
  }
  .react-tel-input .form-control {
    width: 100% !important;
    height: 50px !important;
  }
  .react-tel-input .form-control.invalid-number {
    border: 1px solid #000 !important;
    border-radius: 0;
    background: #fff !important;
  }
  .react-tel-input .form-control.invalid-number:focus {
    border: 2px solid #fc4c02 !important;
  }
  .print {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}


.dopFieldsTableWrapper{
  margin-top: 15px;
  h2{
    margin: 0;
  }
  >button{
    margin: 10px 0;
  }
  >div{
    margin-bottom: 50px;
  }
}


.dopFieldsInputContainer{
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  input{
    width: 90%;
  }
}

.time_actions-buttons{
  position: absolute;
  right: -106px;
}