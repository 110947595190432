.title-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 40px;
}

.title {
  position: relative;
  color: $title;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 2px;
    width: 100%;
    background: $firm;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.title__btn-back {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  &:hover {
    svg {
      animation: slide 1s infinite;
    }
  }
  svg {
    width: 30px; 
  }
  span {
    font-size: 16px;
    margin: 0 0 0 6px;
  }
}

.device-content {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1695px) {
    flex-direction: column;
  }
}

.boardPreviewBox{
  width: 50%;
  @media (max-width: 1695px) {
    order: 1;
    width: 800px;
    height: 535px;
  }
}



.board-preview-box {
  // min-width: 720px;
}

.board-preview-content {
  // position: sticky;
  // top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.board-editor-box {
  flex: 1;
}

.btn {
  width: max-content;
  background-color: $firm;
  color: $white;
  border: 0;
  padding: 10px 20px;
  cursor: pointer;
}

.btn_save {
  height: min-content;
  display: inline-block;
  margin: 0 0 20px;
}

.big_btn {
  height: 100px;
  width: 500px;
  font-size: 34px;
}
.head-title {
  color: $main;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  outline: none;
  &.open {
    .right {
      transform: rotate(180deg);
    }
  }
  .left {
    margin-right: 10px;
  }
  .right {
    margin-left: 10px;
    margin-top: 3px;
    fill: #FC4C02
  }
}
.small-title {
  color: $main;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  > :first-child {
    margin-left: 10px;
    cursor: pointer
  }
}
