@keyframes slide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.devices {

  &__top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px;
  }

  &__header {
    position: relative;
    color: $title;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      height: 2px;
      width: 100%;
      background: $firm;
    }
  }

  &__btn {
    width: max-content;
    background-color: $firm;
    color: $white;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;

    &_back {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      border: 0;
      background: transparent;
      cursor: pointer;
      
      &:hover {
          svg {
          animation: slide 1s infinite;
          }
      }
      svg {
          width: 30px; 
      }
      span {
          font-size: 16px;
          margin: 0 0 0 6px;
      }
    }

    &_save {
      height: min-content;
      display: inline-block;
      margin: 0 0 20px;
    }
  
  }

  &__content {
    position: relative;
    display: flex;
  }

  &__editor_box {
    min-width: 40vw;
    max-width: 40vw;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1vw;

    > div {
      margin: 1vh 0;
    }
  }

  &__preview_box {
  }
}