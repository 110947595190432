.layout__body {
  &_box {
    display: flex;
    flex: 1;
    align-items: stretch;
  }

  &__columns {
    &_box {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex: 1;
    }

    &__title {
      display: flex;
      height: 14%;
      flex: 1;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 0px 0;
      }

      &__item {}
    }

    &__cell {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      text-align: center;

      &_box {
        display: flex;
        flex: 1;
      }
    }
  }

  &__main {

    &_box {
      display: flex;
      flex-direction: column;
    }

    &__client {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      > p {
        text-align: center;
      }

      &.alternate {
        &::before {
          content: '';
          background-image: url('../../../common/images/boardArrow.svg');
        }
        align-items: flex-start;
        flex-direction: column-reverse;
        justify-content: flex-start;
      }
    }

    &__title {
      margin: 0 0 5%;
    }

    &__win__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      text-align: center;
      &.alternate {
        align-items: flex-start;
        flex-direction: column-reverse;
        justify-content: flex-start;
      }
    }
  }

  &__info {
    &_box {
      display: flex;
      flex-direction: column;
      flex: 1;
      // max-width: 20vw;
    }

    &__top {
      display: flex;
      flex: 1;

      &__schedule {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        > p {
          white-space: pre-line;
        }

        &_box {
          display: flex;
          flex: 1;
          flex-direction: column;
          border: 1px solid #a19fa0;
        }

        &__border {
          position: absolute;
          top: 0;
          width: 80%;
          height: 1px;
          background: #000;
        }
      }

      &__news_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid #a19fa0;
      }
    }

    &__video {
      font-size: 66px;
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      overflow: hidden;

      > video {
        max-width: 100%;
      }
    }

  }
}
