$light: (
    main: #25547A,
    dmain: #0b1117,
    lmain: rgba(209, 208, 241, 0.1),
    green: #a4c328,
    greenl: rgba(213, 255, 184, 0.2),
    accent: #fc4c02,
    accentl: rgba(252, 76, 2, 0.2),
    bg: #fbfbfb,
    bgTable: #fff,
    bgButton: #fff,
    table2n: #f5f7f3,
    lightText: #454545,
    disable: #cbcbcb,
    disableText: #0d1a28,
    text: #0d1a28,
    buttonAccent: #FC4C02,
    buttonHover: #fff,
    buttonText: #25547A,
    blue: #81c4ca,
);

.theme_light_accent {
  background: #fc4c02;
}

.text_theme_light_buttonHover {
  background: #fff;
}