.preorder {
  display: flex;
  align-items: start;
  padding-top: 10px;
  gap: 5px;

  &__form {
    &__row {
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
  }

  &__queue {
    &__info {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
    }
  }

  &__info {
    width: 50%;
  }

  &__calendar {
    width: 50%;

    &__header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }

    &__btn {
      width: 100%;
      height: 100%;
      background: none;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }


    &__cell {

      &__text {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
      }

      & > * {
        display: flex;
        align-items: center;
      }
    }
  }

  &__select {
    &__box {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }
  }

  &__slot {
    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    &__pin {
      display: flex;
      align-items: end;
      gap: 5px;
    }
    &__date {
      width: 600px;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 10px;
      &__select {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 5px;
        width: 100%;
      }
    }
  }
}

.free__cell {
  background-color: rgba(0, 255, 0, 0.2);
}

.busy__slot {
  background-color: rgba(245, 39, 39, 0.2);
}