
.board-editor-box {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 0 40px 0 0;
  @media (max-width: 1695px) {
    order: 2;
  }
}

.common-settings-box {
  display: flex;
  margin: 0 0 10px;
}
.commonSettingsBox {
  margin-left: 30px;
  .elementBox {
    display: flex;
    flex-wrap: wrap;
  }
}
.common-settings-size {
  display: flex;
  flex-wrap: wrap;
  min-width: 500px;
}

.common-settings-box__settings-description-box{
  display: flex;
}


.upload-images-box {
  &.true {
    padding: 30px 0 0;
    .labels-box {
      margin: 0 -40px;
      display: flex;
      flex-wrap: wrap;
      > div {
        margin: 0 40px;
        max-width: calc(50% - 80px);
      }
    }
    .upload__label__box {
      flex: 1;
    }
    .upload-label {
      padding: 20px 10px;
    }
    .upload-label_preview-image {
      flex-basis: 30%;
    }
  }
  width: 100%;
}
.common-settings-box__setting-box {
  min-width: 200px;
  width: max-content;
  margin: 0 20px 0 0;
}

.common-settings-box__setting-box__label-icon{
  display: flex;
  align-items: center;
  > :first-child {
    margin-left: 10px;
    cursor: pointer
  }

}


.accordeon-btn {
  color: $firm;
  text-decoration: underline;
  cursor: pointer;
}

.labels-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.upload__label__box {
  flex-basis: 46%;
  max-width: 420px;
  margin-bottom: 60px;
  &:nth-child(odd) {
    margin-right: 20px;
  }
}

.upload-item-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
form {
  .upload__label__box {
    margin: 0 !important;
    max-width: 100%;
    flex-basis: 100%;
  }
}
.upload-item_content {
  display: flex;
  justify-content: space-between;
  &.file {

  }
}

.upload-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  border: 1px dashed #ccc;
  transition: .4s;
  span:first-child {
    color: #9e9e9e;
    margin: 0 0 20px;
    text-align: center;
  }
  span:nth-child(2) {
    color: $firm;
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
  }
}

.upload-title-box {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.upload__label__box__del-btn {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 3px;
  right: 3px;
  color: $firm;
  line-height: 16px;
  cursor: pointer;
  > img {
    width: 100%;
    height: 100%;
  }
}

.upload-label_preview-image {
  text-align: center;
  position: relative;
  display: flex;
  flex-basis: 46%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 120px;
  border: 1px dashed #ccc;
  &.false {
    img {
      width: 100%;
      height: auto
    }
  }
  img {
    max-width: 100%;
    height: 100%;
  }
}

.tab-header {
  position: relative;
  border: 1px solid #ccc;
  padding: 10px 10px;
  margin: 0 5px 0 0;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
  .corner {
    position: absolute;
    top: 2.5px;
    right: -7px;
    border-left: 1px solid #ccc;
    width: 15px;
    height: 14.4px;
    transform: 50% 50% 0;
    transform: rotate(135deg);
  }
  &:hover {
    background: $firm!important;
    color: $white;
  }
  &:hover .corner {
    background: $firm!important;
  }
}

.tab-header.active {
  background: $firm!important;
  color: $white;
  &:after{
    background: $firm!important;
  }
  .corner {
    background: $firm!important;
  }
}

.style-settings {
  margin: 20px 0 0;
}

.label_text {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  max-width: 200px;
  justify-content: flex-end;
  &_column {
    flex-direction: column;
    align-items: flex-start;
    span {
      margin: 0 0 10px!important;
    }
  }
  &_fontWeight {
    > div {
      min-width: auto;
      width: 65%;
    }
  }
  span {
    flex: 2;
    margin: 0 10px 0 0;
    text-align: right;
  }
  textarea {
    min-height: 100px;
    resize: none;
    width: 100%;
    padding: 6px;
    font-size: 14px;
  }
  input {
    width: 40%;
    min-width: auto;
    flex: 1;
    text-align: center;
  }
}

.color__picker__box {
  width: 40%;
  position: relative;
  max-width: 100px;
  min-width: 100px;
}

.style-settings-box {
  display: flex;
}

.text-box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin: 0 40px 0 0;
}
