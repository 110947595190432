.settings {

  &__labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__common {
    display: flex;
    flex-wrap: wrap;

    &__block {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      width: 100%;
      margin-bottom: 30px;

      > p {
        display: flex;
        margin-bottom: 15px;
      }

      > input {
        border: 1px solid rgba(0, 0, 0, .1);
        transition: border 0.1s ease-out;
        padding: 10px 15px;
        margin: 0;
        min-width: 210px;
        max-width: 50%;
        &:focus {
          outline: none;
        }
      }

      .element_box {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &__images_upload {
    width: 100%;

    &__btn {
      color: $main;
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 10px;
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      cursor: pointer;
      outline: none;
      &.open {
        .right {
          transform: rotate(180deg);
        }
      }
      .left {
        margin-right: 10px;
      }
      .right {
        margin-left: 10px;
        margin-top: 3px;
        fill: #FC4C02
      }
    }

    &.true {
      padding: 0;
      .settings__labels {
        margin: 0 -40px;
        display: flex;
        flex-wrap: wrap;
        > div {
          margin: 0 40px;
          max-width: calc(50% - 80px);
        }
      }
      .upload__label__box {
        flex: 1;
      }
      .upload__label__el {
        padding: 20px 10px;
      }
      .upload__label__box__preview {
        flex-basis: 30%;
      }
    }
  }

  &__element {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    &__item {
      position: relative;
      border: 1px solid #ccc;
      padding: 10px;
      margin: 0 5px 5px 0;
      cursor: pointer;
      font-weight: 500;
  
      .active {
        background: $firm!important;
        color: $white;
        &:after{
          background: $firm!important;
        }
      }
  
      &:hover {
        background: $firm!important;
        color: $white;
      }
    }
  }

  &__styles {
    width: 100%;
    &__el {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .text {
        width: 80%;
      }

      > p {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0;
        font-weight: 500;
      }

      > textarea {
        min-height: 100px;
        min-width: 300px;
        width: 100%;
        resize: none;
        padding: 6px;
        font-size: 14px;
        border: 1px solid rgba(211, 211, 211, 0.746);
      }

      &__label {
        margin: 0 10px 0 0;
        cursor: pointer;
      }
    }
  }

  &__btn_to_change {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      cursor: pointer;
      height: 40px;
      border: 1px solid rgba(0,0,0,.1);
    }
}

// --------- ???
.settings__common__setting-box {
  min-width: 200px;
  width: max-content;
  margin: 0 20px 0 0;

  &__label-icon{
    display: flex;
    align-items: center;
    > :first-child {
      margin-left: 10px;
      cursor: pointer
    }
  }
}
// --------- ???

.upload {
  &__label {
    flex-basis: 46%;
    max-width: 420px;
    margin-bottom: 60px;

    &:nth-child(odd) {
      margin-right: 20px;
    }

    &__box {
      margin: 0 !important;
      max-width: 100%;
      flex-basis: 100%;

      &__preview {
        text-align: center;
        position: relative;
        display: flex;
        flex-basis: 46%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        height: 120px;
        border: 1px dashed #ccc;
        &.false {
          img {
            width: 100%;
            height: auto
          }
        }
        img {
          max-width: 100%;
          height: 100%;
        }
      }
    }

    &__el {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 120px;
      border: 1px dashed #ccc;
      transition: .4s;
      span:first-child {
        color: #9e9e9e;
        margin: 0 0 20px;
        text-align: center;
      }
      span:nth-child(2) {
        color: $firm;
        text-decoration: underline;
        font-weight: bold;
        text-align: center;
      }
      &__delete {
        position: absolute;
        width: 15px;
        height: 15px;
        top: 3px;
        right: 3px;
        color: $firm;
        line-height: 16px;
        cursor: pointer;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1vh 0;

    &__title {
      color: $main;
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 10px;
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      > :first-child {
        margin-left: 10px;
        cursor: pointer
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
    }
  }
}

#settings__styles__video__volume {
  z-index: 10;
  width: 100%;
  outline: none;
  
  &:focus {
    outline: none;
  }
}