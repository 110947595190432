
@font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: url("Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-weight: 100;
    src: url("Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Roboto";
    font-weight: 800;
    src: url("Roboto/Roboto-Bold.ttf") format("truetype");
}



@font-face {
    font-family: "RobotoSlab";
    font-weight: 400;
    src: url("Roboto_Slab/RobotoSlab-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "RobotoSlab";
    font-weight: 100;
    src: url("Roboto_Slab/RobotoSlab-Light.ttf") format("truetype");
}
@font-face {
    font-family: "RobotoSlab";
    font-weight: 800;
    src: url("Roboto_Slab/RobotoSlab-Bold.ttf") format("truetype");
}



@font-face {
    font-family: "Ubuntu";
    font-weight: 400;
    src: url("Ubuntu/Ubuntu-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Ubuntu";
    font-weight: 100;
    src: url("Ubuntu/Ubuntu-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Ubuntu";
    font-weight: 800;
    src: url("Ubuntu/Ubuntu-Bold.ttf") format("truetype");
}



@font-face {
    font-family: "Comfortaa";
    font-weight: 400;
    src: url("Comfortaa/Comfortaa-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Comfortaa";
    font-weight: 100;
    src: url("Comfortaa/Comfortaa-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Comfortaa";
    font-weight: 800;
    src: url("Comfortaa/Comfortaa-Bold.ttf") format("truetype");
}
