
.MultiSelect{

    &__Main{
        width: 100%;
        position: relative;
    }
    &__Box{
        width: 100%;
    }

    &__Values {
        box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
        background: #fff;
        min-height: 48px;
        border-radius: 3px;
        padding: 9px 5px;
        display: flex;
        overflow: overlay;
    }

    &__Search{
        font-size: 16px;
        border: none;
        outline: none;
        background: #fff;
        height: 35px;
        color: #0d1a28;
        border-radius: 3px;
        padding: 8px 10px;
    }
    &__Search-Items{
        max-height: 300px;
        overflow: auto;
    }

    &__Selected-Item-List{
        display: flex;
        flex: 1;
        flex-wrap: wrap;

    }

    &__Selected-Item{
        height: fit-content;
        background: gainsboro;
        border-radius: 3px;
        padding: 0 11px;
        margin: 3px 3px;
        display: flex;
        max-width: max-content;
    }


    &__Selected-Item-Text{
        font-size: 16px;
        display: flex;
        align-items: center;
    }
    

    &__Delete-All-Icon{
        align-items: baseline;
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 0px 8px 0px 8px;
        svg{
            fill: red;
            width: 14px !important; 
            cursor: pointer;
        }
    }

    &__Selected-Item-Close-Cross{
        font-size: 10px;
        margin-left: 5px;
        &:hover{
            cursor: pointer;
        }
    }


    &__Selected-Value{
        font-size: 16px;
        padding: 10px 10px 10px 10px;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed gainsboro;
        &__Done{
            font-size: 16px;
            padding: 10px 10px 10px 10px;
            display: flex;
            align-items: center;
            border-bottom: 1px dashed gainsboro;
            background: #dcdcdc;
        }
        &:hover{
            cursor: pointer;
        }
    }

    &__Content{

        &__Open{
            display: flex;
            flex-direction: column;
            width: 100%;
            position : absolute;
            box-shadow: 0 1px 3px rgba(0,0,0,.1);
            background: #fbfbfb;
            z-index: 100;
        }
        &__Close{
            display: none;
        }
    }

    &__Empty-Search{
        font-size: 16px;
        padding: 10px 10px 10px 10px;
        display: flex;
        align-items: center;
       
    }
}