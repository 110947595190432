.npsCardBox {
    width: 100%;
    overflow: hidden;
  }

  .npsPeopleBox {
    width: 80px;
  }

  .npsPeople {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .npsPeopleNumber {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: white
  }

  .npsPeopleDescBox {
    display: flex;
    flex-direction: column;
  }

  .npsPeopleAllCnt {
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  .npsPeoplePercent {
    display: flex;
    justify-content: center;
    font-size: 14px;
  }

  .npsPeopleGroupBox {
    margin-top: 20px;
    display: flex;
    justify-Content: space-around;
    height: 180px;
  }


  .tolpa{
    display: flex;
    justify-content: center;
    height: 93px;
    width: 93px;
  }


.toltopaDesc {
    display: flex;
    flex-direction: column;
}

.npsScoreBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.npsScore {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
}
.npsScorePercent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-left: 10px;
  font-weight: 400;
}

.tolpaDescItem {
    display: flex;
    justify-content: center;
}

//.tolpaBox{
//    display: flex;
//    flex-direction: column;
//    height: max-content;
//    width: max-content;
//}

.circle {
    height: 300px;
}

.arrow {
  width: 31px;
  height: 125px;
  position: absolute;
  left: 45%;
  bottom: 130px;
  transform-origin: center 111px;
  transition: 0.3s;
}

.cercleBox {
    display: flex;
    flex-direction: column;
    position: relative;
}



.intervalReportTitle{
  display: flex;
    justify-content: space-between;
}

.itervalReprotBox {
  padding: 25px 0px 25px 0px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,.1);
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 1775px;
  overflow: auto;
}

.title {
  display: flex;
  border-bottom: 1px solid;
    border-bottom-color: lightgray;
}

.titleCell{
  padding: 20px 10px 20px 10px;
  display: flex;
  justify-content: center;
}

.bodyCell{
  width: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.historyDateDesc{
  width: 167px;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.bodyCellBig{
  width: 167px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.redCell{
  margin-left: 3px;
}

.intervalReport_bodyBox{
  display: flex;
  /* margin-left: 126px; */
  padding-top: 10px;
}

.dataCell {
  display: flex;
}

.intervalReprot_title_avgTime{
  min-width: 240px;
}

.intervalReprot_title_deference{
  min-width: 255px;
}

.asd {
  width: 126px;
}

.exportData{
  width: 100%;
  display: flex;
}

.download-table-xls-button{
  max-width: 40px;
  height: 40px;
  min-width: 200px;
  line-height: 0.8;
  border: 0;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #607d8b;
  margin: 30px 0 15px;
}
