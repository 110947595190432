.carousel {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 100%;
	overflow: hidden!important;
  
	> ul {
	  flex: 1;
	  height: 100%;

	  > li {
		display: flex;
		justify-content: center;

		> img {
			display: block;
			max-width: 100%;
			object-fit: cover;
			margin: 0 auto;
		}
	  }
  
	  > div {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
		padding: 20px 0 0;
  
		> p:nth-of-type(1) {
		  display: flex;
		  align-items: flex-start;
		  padding: 0px 10px 0;
		  width: 100%;
		  height: 100%;
		  overflow: hidden;
		}
	  }
	}

	&__dots_box {
		position: absolute;
		margin: 0;
		padding: 0;
		list-style-type: none;
		bottom: .5em;
		text-align: center;
		width: 100%;
	
		> li {
			display: inline-block;
			min-width: 10px;
			min-height: 10px;
			width: .6em;
			height: .6em;
			background-color: #BEC0BC;
			margin: 0 .5em;
			border-radius: 50%;
			overflow: hidden;
			cursor: pointer;
			text-indent: 100%;
		}
	
		[aria-current="true"] {
			pointer-events: none;
			cursor: auto;
			background-color: black;
		}
	}

	&__arrow {
		&_box {
			position: absolute;
			width: 100%;
			top: 50%;
			margin-top: -1em;
			padding: 0;

			> button {
				position: absolute;
				font-size: inherit;
				background-color: black;
				height: 2em;
				width: 2em;
				opacity: .5;
				padding: 0;
				border: 0;
				outline: none;
				cursor: pointer;
			}
		}

		&__prev {
			left: 0;
		}

		&__next {
			right: 0;
		}
	}
}