.tooltip-box{
    text-align:center;
    position:relative;
    align-items: center;
    display: flex;
}

.tooltip-box:before {
    content:attr(data-description);
    box-sizing:border-box;
    display:flex;
    justify-content: center;
    align-items: center;
    background:rgba(0,0,0,.7);
    color:white;
    position:absolute;
    left: 50%;
    width: max-content;
    max-width: 250px;
    transform: translateX(-50%);
    padding: 5px;
    line-height:25px;
    border-radius:5px;
    visibility: hidden;
    transition:.25s ease-in-out;
    font-size: 13px;
    bottom: 25px;
    cursor: pointer;
    z-index: 2;
}
  
.tooltip-box:after {
    content:'';
    display:block;
    position:absolute;
    top:35px;
    left:50%;
    margin-left:-8px;
    height:0;
    width:0;
    border-left:8px solid transparent;
    border-right:8px solid transparent;
    border-top:8px solid rgba(0,0,0,.7);
    transition:.25s ease-in-out;
    visibility: hidden;
    top: -5px;
    z-index: 2;
}

.tooltip-box:hover:before {
    visibility: visible;
    z-index: 2;
}
  
.tooltip-box:hover:after {
    visibility: visible;
    z-index: 2;
}
