.customize-Collapse {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    font-weight: 500;
  
  
    &-TextBox {
      padding-top: 0;
    }
    & .settings__styles__el {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
    }
    
    &-Margin-Bottom {
      margin-bottom: 20px;
    }
  
    &-Colors .ant-collapse-content-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
  
      & .box__color {
        max-width: 240px;
        min-width: 240px;
        margin-right: 20px;
      }
    }
  }
  
  .customize-Collapse-Header-No-Top-Padding > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px!important;
  }
  
  .ant-collapse-header {
    margin-right: 10px;
    flex: 1;
    text-align: left;
    font-weight: 500;
    padding: 12px 0;
  }
  
  .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-left: 24px;
  }


  
.select_input { 
    &_box {
        position: relative;
        min-width: 150px;
        max-width: 100%;
        margin: 0;
        border-radius: 3px;
        text-align: left;
    }

    &__div {
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        // color: ${p => p.theme.lightText};
        background: #fff;
        border: 1px solid rgba(0,0,0,.1);
        width: 100%;
            white-space: nowrap;
        svg {
            margin-left: 15px;
            margin-top: 3px;
            // fill: ${p => p.theme.accent};
            pointer-events: none;
        }
    }

    &__close {
        position: fixed;
        top: 0;
        left: 0;
        width: 90%;
        height: 90%;
    }

    &__options {
        max-height: 400px;
        overflow-y: auto;
        position: absolute;
        border: 1px solid rgba(0,0,0,.1);
        z-index: 3;
        width: 100%;
        min-width: max-content;
        background: #fff;
        top: auto;
        bottom: auto;
        left: 0;

        &__item {
            cursor: pointer;
            transition: 0.1s ease-out;
            background: #fff;
            // color: ${p => p.theme.text};
            border-bottom: 1px solid rgba(0,0,0,.1);
            &:last-child {
                border-bottom: none;
            };
            &:hover {
                background: #fff;
                border-color: transparent;
                // color: ${p => p.theme.buttonHover}
            };
            width: max-content;
            min-width: 100%;
            padding: 10px 15px;
        }
    }
}
