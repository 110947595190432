.multiSelectBox {
  /* max-width: 300px; */
  width: 100%;
  .save {
    background: #FC4C02;
    color: white;
    padding: 10px 15px;
    margin-top: 15px;
    cursor: pointer;
    border: none;
  }
}

.multiSelectBox input {
  width: 100%;
  height: 40px;
  padding: 5px;
}

.multiSelectContentBox {
  min-height: 46px;
  margin-top: 10px;
  //box-shadow: inset 0px 0px 3px rgba(0,0,0,.4), inset 0px 0px 15px rgba(0,0,0,0.1);
  .box {
    display: flex;
    //padding: 5px;
    margin: -3px;
    flex-wrap: wrap;
    > p {
      padding: 3px;
    }
  }
}

.contentItem {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0,0,0,.2);
  margin: 3px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  &:after {
    position: absolute;
    top: 0;
    right: 0px;
    width: 13px;
    height: 13px;
    padding-bottom: 2px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    content: '\271A';
    background: #fc4802;
    color: white;
    transform: rotate(45deg);
    font-size: 10px;
    border-radius: 50%;
    font-weight: 1000;
  }
  &:hover {
    &:after {
      display: none;
    }
    .del {
      opacity: 1;
    }
  }
  .del {
    position: absolute;
    z-index: 2;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fc4802;
    font-size: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.contentItem p {
  cursor: pointer;
}

.multiListBox {
  display: flex;
  //border: 1px solid #cecece;
  padding: 10px 0 0;
}

.listBox {
  flex: 1;
  box-shadow: inset 0px 0px 3px rgba(0,0,0,.4), inset 0px 0px 15px rgba(0,0,0,0.1);
  margin: 0 5px 0 0;
  max-height: 250px;
  overflow: auto;
}

.listBox:last-child {
  margin: 0;
}

.listItem {
  background: rgba(0,0,0,0.05);
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  transition: .1s;
  padding: 5px 15px;
}

.listItem:hover {
  background: #fc4c02;
  color: white;
}
