.label {
  margin-bottom: 10px;
}


.input {
  border: 1px solid rgba(0, 0, 0, .1);
  transition: border 0.1s ease-out;
  text-align: left;
  padding: 10px 15px;
  font-size: inherit;
  &:focus {
    outline: none;
  }
    &_box {
    }
}

.check {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 15px;
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
        position: absolute;
        content: "";
        border-radius: 50%;
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    &_box {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        width: 40px;
        height: 26px;
        > input{
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
}

.multiselect {
  width: 100%;
  height: 100%;
  min-width: 200px;

  > div {
    height: 100% !important;
  }
}

.select {
  @extend .multiselect;
  > div {
    align-items: center;
  }
}

.zone-title {
  color: rgb(37, 84, 122);
  position: relative;
  text-transform: uppercase;
  font-size: 24px;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  padding: 8px 16px;
  margin-bottom: 25px;
}

.zone-name {
  color: #fc4802;
}

.button-form {
  &-enter {
    opacity: 0;
    max-height: 0;
  }

  &-enter-active {
    max-height: 700px;
    overflow: hidden;
    opacity: 1;
    transition: .7s ease;
  }

  &-exit {
    max-height: 700px;
    overflow: hidden;
  }

  &-exit-active {
    opacity: 0;
    max-height: 0;
    transition: .7s ease;
  }
}

.button-info {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: .7s ease;
  }

  &-exit-active {
    opacity: 0;
    transition: .7s ease;
  }
}

.ant-card {
  &-meta-title, &-head-title {
    white-space: normal !important;
    text-overflow: initial !important;
  }
  &-head-wrapper {
    align-items: flex-start !important;
  }
}

.card-selected {
  border-color:#607d8b !important;
  box-shadow: 0 1px 2px -2px rgba(96, 125, 139, .3), 
  0 3px 6px 0 rgba(96, 125, 139, .2),
  0 5px 12px 4px rgba(96, 125, 139, .1);
}

.button-icon {
  &:hover .delete-icon {
    background: rgba(0, 0, 0, .7);
    opacity: 1;
  }
}

.delete-icon {
  transition: .4s ease;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  padding: 10px;
  border-radius: 10px;
}

.screen-arrow {
  &::after {
    content: "";
    position: absolute;
    display: flex;
    top: 50%;
    right: -18px;
    transform: translateY(-50%);
    box-sizing: border-box;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent transparent rgb(255, 255, 255);
    border-image: initial;
    z-index: 2;
    filter: drop-shadow(rgba(0, 0, 0, 0.05) 2px 0px 1px);
  }
}