@import '../themes2/colors';

.customize-Collapse {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  font-weight: 500;

 
  &-TextBox {
    padding-top: 0;
  }
  & .settings__styles__el {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
  }
  
  &-Margin-Bottom {
    margin-bottom: 20px;
  }

  &-Colors .ant-collapse-content-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & .box__color {
      max-width: 220px;
      margin-right: 20px;
    }
  }
}

.customize-Collapse-Header-No-Top-Padding > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px!important;
}

.ant-collapse-header {
  margin-right: 10px;
  flex: 1;
  text-align: left;
  font-weight: 500;
  padding: 12px 0;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding-left: 24px;
}

.box {
    &__align {
    // div
        position: static;
        top: 0;
        bottom: auto;
        display: flex;
        width: 140px;
        z-index: 3;
        flex-wrap: wrap;
        overflow: visible;
        border: 1px solid rgba(0,0,0,.1);
        input {
            width: 100% !important;
        }

        &__item {
         width: 33.3%;
         display: flex;
         cursor: pointer;
         align-items: center;
         justify-content: center;
         height: 40px;
        //  color: ${p => (p.active ? p.theme.accent : p.theme.disable)};
         &:hover {
        //    color: ${p => p.theme.accent};
         }
       }
    }

    &__check { 
    // div
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(0,0,0,.1);
        cursor: pointer;
        margin-right: 10px;
        &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
        background: transparent;
        }
    }

    &__color {
    // label
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 220px;
        position: relative;
        margin-bottom: 20px;

        span {
          margin-right: 10px;
          flex: 1;
          text-align: left;
        }

        .refresh {
          font-size: 13px;
          padding: 0 1vw;
          cursor: pointer;
          transition: opacity 0.15s ease-out;

          // &:hover {
          //   display: flex;
          //   align-items: center;
          //   max-width: max-content;
          //   padding: 5px 15px;
            // background: ${p => p.theme.accent};
            // color: ${p => p.theme.buttonHover};
          // }
        }

        &__picker {
            width: 40%;
            position: relative;
            background-color: transparent !important;
            flex: 1;
            max-width: 100px;
            min-width: 100px;
            padding: 0;
    
            &__btn {
                max-width: 100px;
                min-width: 100px;
                border: 1px solid rgba(0,0,0,.1);
                height: 40px;
                cursor: pointer;
    //   &:hover {
    //     animation: ${animationHover(p => p.theme.accentl)} 0.3s ease-out;
    //   }
            }

            &__close {
                position: fixed;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
            }
        }

        &__gradient {
            position: absolute;
            z-index: 2;
            min-width: 587px;

            &__popup {
                  position: relative;
                  z-index: 9;
                  display: flex;
                //   background: ${p => p.theme.bg};
                  background: #fff;
                  flex-wrap: wrap;
                  justify-content: space-evenly;
                  padding: 20px;
                  border-radius: 3px;
                  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
                  // > div {
                    p {
                      // + theme
                      color: rgba(0,0,0,.8);
                      margin-bottom: 10px;
                    }
                    ul {
                      position: relative;
                      padding: 0px;
                      width: max-content;
                      margin-top: 10px;
                      border-radius: 3px;
                      overflow: hidden;
                      display: flex;
                      margin-bottom: 10px;
                  }
                  > div:first-child {
                    width: 100%;
                  }
            }

            &__btn {
              // color: ${p => (p.active ? p.theme.buttonHover : p.theme.text)};
              // background: ${p => (p.active ? p.theme.accent : p.theme.bgTable)};
              padding: 10px 15px;
              margin: 0px;
              cursor: pointer;
              transition: 0.1s ease-out;
              &:hover {
                // color: ${p => p.theme.buttonHover};
                // background: ${p => p.theme.accent};
              }
            }
        }
    }

    &__input_margin {
    // div`
        position: relative;
        width: 100%;
        &:hover {
            > p {
                max-width: 100%;
                padding: 12px 15px;
            // color: ${p => p.theme.buttonHover};
                opacity: 1;
            }
        }
        > p {
        overflow: hidden;
        z-index: 2;
        transition: 0.15s linear;
        opacity: 0;
        padding: 10px 0;
        position: absolute;
        max-width: 0;
        //   background-color: ${p => p.theme.accent};
        //   color: ${p => p.theme.buttonHover};
        top: 0;
        height: 100%;
        left: 100%;
        }

        > input {
          border: 1px solid transparent;
          margin-bottom: 0;
          border-bottom: 1px solid rgba(0,0,0,.1);
          padding: 10px 15px;
          margin: 0;
          width: auto;
        //   color: ${p => p.theme.text};
          text-align: center;
          outline: none;
        //   &:focus {
        //     border-bottom: 2px solid ${p => p.theme.green};
        //   }
        }

    }
}

