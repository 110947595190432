body { margin: 0; }
* { box-sizing: border-box; margin: 0; padding: 0; }

@keyframes scroll {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0)
  }
}

.marquee {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  span {
    width: 100%;
    display: inline-block;
    animation: scroll 40s infinite linear;
  }
}

.layout {

  &_box {
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    /* Запрет выделения текста и таскания картинок */
    -webkit-user-select: none;
    -webkit-user-drag: none;
    touch-action: none;
    overflow: hidden;
  
    > * {
      font-weight: normal;
    }
  }

  &__header {
    &_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      background-size: cover!important;
    }
  
    &__logo {
      padding: 0;
      > img {
        width: auto;
        height: inherit;
        object-fit: contain;
        align-self: flex-start;
        display: flex;
      }
    }
  
    &__timer {
      display: flex;
      justify-content: center;
    }

    &__text {
      display: flex;
      align-items: center;
      white-space: pre-line;
    }
  }

  &__footer_box {
      display: flex;
      align-items: center;
      height: 5.5%;
  }

  &__marquee {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;

      > p {
        margin: 0 10px;
      }
    }
  }

  &__timer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
  }

  &__absolut_item {
    background-repeat: no-repeat;
    position: absolute;
    z-index: 5;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // align-items: ${(p) => (p.logo ? 'center' : 'flex-start')};
    // flex-direction: ${(p) => (p.logo ? 'center' : 'column')};
    svg {
      width: auto;
      height: 100%;
    }
    img {
      height: 100%;
      width: auto;
    }
    > div {
      width: 100%;
      font-family: inherit;
      line-height: 1;
    }
  }
}