
@import '../../../old/styles/index';

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("Roboto/Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  src: url("Roboto/Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 800;
  src: url("Roboto/Bold.ttf") format("truetype");
}



@font-face {
  font-family: "RobotoSlab";
  font-weight: 400;
  src: url("Roboto_Slab/RobotoSlab-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoSlab";
  font-weight: 100;
  src: url("Roboto_Slab/RobotoSlab-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoSlab";
  font-weight: 800;
  src: url("Roboto_Slab/RobotoSlab-Bold.ttf") format("truetype");
}



@font-face {
  font-family: "Ubuntu";
  font-weight: 400;
  src: url("Ubuntu/Ubuntu-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 100;
  src: url("Ubuntu/Ubuntu-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 800;
  src: url("Ubuntu/Ubuntu-Bold.ttf") format("truetype");
}



@font-face {
  font-family: "Comfortaa";
  font-weight: 400;
  src: url("Comfortaa/Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa";
  font-weight: 100;
  src: url("Comfortaa/Light.ttf") format("truetype");
}
@font-face {
  font-family: "Comfortaa";
  font-weight: 800;
  src: url("Comfortaa/Bold.ttf") format("truetype");
}

.btnAdd {
  cursor: pointer;
    padding: 10px 20px;
    background: #ccc;
    box-shadow: none;
    border-radius: 3px;
    border: 0;
    margin: 5px 5px 25px;
    color: #fff;
    -webkit-transition: 0.1s ease-out;
    transition: 0.1s ease-out;
}

.layoutSettingBox {
  display: flex;
  flex-direction: column;
}
