$mainColor: #3E66FB;
$textColor: #454545;
$borderColor: #909090;
$buttomTextColor: #f9f9f9;
$fontWeight: normal;

#root{
    height: 100%;
}

.websWrapper{
    height: 100%;
    width: 100%;
    background-color: #f9f9f9;
}

.websTeminalScreen {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__header{
        height: 48px;
        width: 100%;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-between;
    }

    &__phone{
        margin: 12px;
        align-items: center;
        display: flex;
        >svg{
            height: 12px;
            width: 12px;
        }
        >span{
            margin-left: 8px;
            color: $textColor;
            font-size: 10px;
            font-weight: $fontWeight;
        }
    }

    &__logo{
        >img{
            height: 24px;
            margin: 12px;
        }
    }

    &__title{
        color: $textColor;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        text-align: center;
        margin-top: 24px;
    }

    &__subTitle{
        color: $textColor;
        font-size: 18px;
        font-weight: 100;
        text-align: center;
        margin: 8px 0px;
    }

    &__buttonsContainer{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: auto;
        margin: 8px 0px;
        >button{
            margin: 4px 12px;
            outline: none;
        }
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
            background-color: gray;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 5px;
        }
    }

    &__navigatinButtons{
        outline: none;
        display: flex;
        justify-content: space-evenly;
        padding: 0px 12px 12px;
    }


    &__button{
        outline: none;
        border: none;
        background: $mainColor;
        color: $buttomTextColor;
        padding: 16px  !important;
        font-size: 14pt;
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: max-content;
        min-height: max-content;
        >img{
            height: 20px;
            width: 20px;
            margin-right: 20px;
        }
        >div{
            display: flex;
            flex-direction: column;
        }
    }
}


.button{
    outline: none;
    &__mainText{
        font-size: 16px;
        font-weight: 400;
        max-width: 180px;
    }
    &__subText{
        margin: 2px;
        font-size: 12px;
    }
    &__extraContainer{
        max-width: 260px
    }
    &__bottom {
        padding: 12px 24px !important;
        border-radius: 12px;
        align-items: center;
        font-size: 16px;
        >img{
            margin-right: 8px
        }
    }
}


.boardScreen{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px 8px 8px 8px;
    overflow: auto;

    &__tablo{
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    &__tabloHeader{
        display: flex;
        justify-content: space-around;
        padding: 0px 4px;
        >span{
            color: $textColor;
            font-size: 24px;
            font-weight: 400;
            text-align: center;
            margin: 8px 0px;
            flex: 1;
        }
    }

    &__tabloBody{
        display: flex;
        flex-direction: column;
        padding: 4px;
        >div:not(:last-child){
            margin-bottom: 8px;
        }
        >div:last-child {
           border-radius: 0 0 8px 8px;
        }
    }

    &__tabloRow{
        display: flex;
        justify-content: space-around;
        min-height: 47px;
        > span {
            color: $textColor;
            font-size: 20px;
            font-weight: 400;
            text-align: center;
            margin: 0 0;
            padding: 8px 0;
            flex: 1;
        }
        > span:first-child {
            border-right: 1px solid #fff;
        }
    }

    &__title{
        color: #454545;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        text-align: center;
        padding: 10px 20px;
        align-items: center;
        border: 1px solid #909090;
        display: flex;
        justify-content: center;
        border-radius: 8px;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    }

    &__container{
        border: 1px solid $borderColor;
        border-radius: 8px;
        margin: 16px 0;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.25);
    }
}



.ticketProcess{
    padding: 8px;
    flex: 1;
    display: flex;
    overflow: auto;

    &__container{
        flex: 1 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    &__okno{
        font-size: 46px;
        font-weight: 400;
        text-align: center;
        margin-top: 16px;
    }
    &__subText{
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        margin-top: 32px;
    }
    &__text{
        font-size: 40px;
        font-weight: 400;
        text-align: center;
    }
    &__ticketNumber{
        font-size: 40px;
        font-weight: 400;
        text-align: center;
        color: #f71515;
    }
    &__button{
        outline: none;
        justify-content: center;
        display: flex;
        margin-top: 32px;
    }
    &__input{
        width: 100%;
        font-size: 18px;
        height: 40px;
        padding: 0px 8px;
        font-weight: 600;
        color: #454545;
        border-radius: 8px;
        outline: none;
        border: 1px solid #909090;
        margin: 18px 0px;
        background: transparent;
        text-align: center;
    }
}



.videoPreloader{
    height: calc(100% - 48px);
    width: 100%;
    background: #f9f9f9;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    position: absolute;
}

.preentryScreen{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    &__container{
        flex: 1 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
}


.webservicecopylink:hover{
    cursor: pointer;
}


.TerminalTransformBox{
    @media (max-width: 1540px) {
        max-width: 410px !important;
        >div{
            transform: scale(0.4);
        }
    }
    @media (max-height: 768px) {
        max-width: 410px !important;
        >div{
            transform: scale(0.4);
        }
    }
}
