.colorPicker{
  
    &__preview{
        height: 40px;
        width: 70px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
    }

    > div > .react-colorful__saturation{
        border-radius: 2px 2px 0 0;
    }
    > div > .react-colorful__last-control{
        border-radius: 0 0 2px 2px;
    }
}


.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    z-index: 1000000000;
  }
  
  .row-dragging td {
    padding: 16px;
  }
  
  .row-dragging .drag-visible {
    visibility: visible;
  }